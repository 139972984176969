import React, { useState } from "react";
import controls from "./controls";
// import useAudioStore from "../store/useAudioStore";


const AudioPlayer = () => {
  // const audioRef = useRef(null);
  const [audio, setThisAudio] = useState("")
  // const { isPlaying } = useAudioStore();

  console.log("AUDIO PLAYER", audio);
  controls.setAudio = setThisAudio;

  return (
    <div key={audio}>
      <audio controls webkit-playsinline="true" playsInline={true}
      onLoadedData={()=>document.getElementById("myPlayer").play()}
      // muted={isPlaying} 
      id="myPlayer">
        <source src={audio} type="audio/mp3"  />
      </audio>
    </div>
  );
};

export default AudioPlayer;
