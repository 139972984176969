import { useEffect } from "react";
import Callpage from "./components/CallPage";
import CustomizedSnackbars from "./components/Snackbar";
import controls, { endCall } from "./components/controls";
import Loading from "./components/Loading";
import AudioPlayer from "./components/AudioPlayer";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./components/FrecallTheme";


function App() {
  
  let urlData = {};

  window.location.search.replaceAll("?","").split("&").forEach(element => {
    const elementList = element.split("=");
    urlData[elementList[0]] = elementList[1];
  })

  // console.log(urlData);

  controls.callerId = urlData.userId || "";
  controls.roomId = urlData.roomId || ""; //sets the frecall Room ID.

  if(["id", "null"].includes(controls.callerId))controls.callerId = null;
  

  // console.log(controls.callerId, ", ", controls.roomId);


  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      // console.log('beforeunload event triggered');
      endCall();
      return (event.returnValue =
        'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return ( 
    <div className="App">
      <ThemeProvider theme={theme}>
        <div hidden><AudioPlayer/></div>
        <Loading/>
        <CustomizedSnackbars/>
        <Callpage/>
      </ThemeProvider>
    </div>
  );
}

export default App;