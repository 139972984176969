import AgoraRTC from "agora-rtc-sdk-ng";
import { getFunctions, httpsCallable } from "firebase/functions";


import { showSnackbar } from "./Snackbar";
// import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { setAgoraToken } from "./firebase";

function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  }

const controls = {
  volume: 5,
  micOn: true,
  speakerOn: true,
  cameraOn: false,
  callAnswered: false,
  callerId: "",
  uidOfAgentOnCall: "",
  uidOfCustomerOnCall: "",
  callDuration: 0,
  myInterval:null,
  logOff: AgoraRTC.setLogLevel(4),
  agoraEngine: AgoraRTC.createClient({ mode: "rtc", codec: "vp9" }),
  microphoneTrack: await AgoraRTC.createMicrophoneAudioTrack(),
  playbackDevices:await AgoraRTC.getPlaybackDevices(),
  // videoTrack: AgoraRTC.createCameraVideoTrack(),
  appId:"",
  roomId: "",
  channelName: "",
  token:"",
  audioTrackOfAgent: null,
  setView: null,
  unsub:null,
  assignedToAnotherRoom: null,

  //audio
  queuedAudio: null,
  welcomeAudio: null,
  readyAudio: null,
  youtubeVideoId: null,
  setAudio: null,

  setLoading: ()=> {},
}

const initAgoraEngine = () =>{

    controls.agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp9" });

    //EVENT HANDLERS

    controls.agoraEngine.on("user-published", async (user, mediaType) =>{
      // Subscribe to the remote user when the SDK triggers the "user-published" event.

      if(!controls.callAnswered) {
        endCall();
        return;
      };

      await controls.agoraEngine.subscribe(user, mediaType);
      // console.log("subscribe success");
  
      // Subscribe and play the remote audio track.
      if (mediaType === "audio")
       {
            controls.uidOfAgentOnCall=user.uid;
            // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
            controls.audioTrackOfAgent = user.audioTrack;
            // Play the remote audio track. 
            controls.audioTrackOfAgent.play();
            showSnackbar("Agent connected: ");
       }
  
      // Listen for the "user-unpublished" event.
      controls.agoraEngine.on("user-unpublished", user =>
      {
        // console.log(user.uid + "has left the channel");
        showSnackbar("Agent has left the call.", "warning");
      });
    });
}

const joinCallQueue = async() => {
    const functions = getFunctions();

    const handleUserCallQueue = httpsCallable(functions, 'handleUserCallQueue');

    let joinStatus = false;

    try {
        if((controls.callerId || "").length < 5){
            controls.callerId = "null" + generateRandomString();
        }

        // console.log(controls.callerId);

        await handleUserCallQueue({
            addUser: true,
            callerId: controls.callerId,
            roomId: controls.roomId,
          }).then((value)=>{
            joinStatus = value.data;
            if (joinStatus) {
                showSnackbar("You are now connected to call center.");
              } else {
                showSnackbar("We could not connect you.", "error");
              }
          }) 

          return joinStatus;
    } catch (error) {
        console.log(error);
       return joinStatus;
    }


}

const joinCall = async (setTime) => {
    try{
    await setAgoraToken();

    // console.log("TOKEN:", controls.token);
    
    initAgoraEngine();

    await controls.agoraEngine.join(controls.appId, controls.channelName, controls.token);

    try {
        controls.microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack()
    } catch (error) {
        
    }

    await controls.agoraEngine.publish(controls.microphoneTrack);
    controls.microphoneTrack.setEnabled(controls.micOn)
    showSnackbar("Call connected.")
    // console.log("AUDI0 PUBLISHED");

    controls.callAnswered = true;
    controls.callDuration = 0;
    controls.myInterval = setInterval(()=>{
        setTime(pTime=>{
            const newTime = pTime+1;
            controls.callDuration = newTime;
            return newTime
        });
    }, 1000)

    } catch(err){
      controls.callAnswered = false;
      // console.log("EEEEEERRR",err)
    }
    
  
    
}

const endCall = async() => {


  try {
    // window.close();

  } catch (error) {
    
  }

    const functions = getFunctions();

    const handleUserCallQueue = httpsCallable(functions, 'handleUserCallQueue');

    // let endStatus = false;

    try{
      try {
        await handleUserCallQueue({
          callerId: controls.callerId,
          email: "",
          roomId: controls.roomId,
        })
    
      } catch (error) {
        
      }


      try{
    controls.microphoneTrack.close();
    await controls.agoraEngine.leave();
      } catch(err){}
    // controls.microphoneTrack.setEnabled(false)

    console.log("ENDED");

    controls.micOn = false;
    showSnackbar("Call ended");
    // controls.userIdOnCall = "";
    controls.callAnswered = false;
    clearInterval(controls.myInterval);
    controls.callDuration = 0;
    // setTime(0)
    controls.unsub();
    // window.close();
    } catch (err){
        console.log(err);
    }
}

export default controls;

export {initAgoraEngine, joinCall, endCall, joinCallQueue};