import React, { useState } from 'react';
import {Card, Typography, Rating, TextField, Button, Box, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import controls from './controls';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function EndCall(props) {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [submited, setSubmit] = useState(false);

  
      // Handle change in rating
      const handleRatingChange = (event, newValue) => {
        setRating(newValue);
      };
    
      // Handle change in comment
      const handleCommentChange = (event) => {
        setComment(event.target.value);
      };
    
      // Handle form submission
      const handleSubmit = () => {
  
        const functions = getFunctions();

        const submitRating = httpsCallable(functions, 'submitRating');
    

        submitRating({
          callerId: controls.callerId,
          channelName: controls.channelName,
          rating: rating,
          comment: comment,
        });
  
        console.log(`Rating: ${rating}, Comment: ${comment}`);

        setSubmit(true);

        // window.close();
        // You can also handle submission logic here
      };
  
    const styles = {
      container: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // height: '100vh',
      },
      card: {
        maxWidth: '400px',
        padding: '32px',
        borderRadius: '16px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        backgroundColor: '#fff', // Adjust the color based on your design
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      logo: {
        marginBottom: '24px',
        width: '120px', // Adjust according to the actual size of your logo
        height: 'auto',
      },
      title: {
        color: '#333', // Adjust the color based on your design
        marginBottom: '12px',
      },
      rating: {
        marginBottom: '24px',
        color: '#FF6F00',
       fontSize: '50px',
      },
      commentBox: {
        width: '100%',
        marginBottom: '24px',
      },
      submitButton: {
        width: '100%',
        padding: '16px 0', // Optional: adjust padding for better visual appeal
        backgroundColor: '#004d40', // Adjust the color based on your design
        color: '#fff',
      }
    };
  
    return (  
      <div style={styles.container}>
        <Card style={styles.card}>
        <PersonRoundedIcon  sx={{fontSize:"200px", color:grey[500]}}/>
        <Typography sx={{ fontWeight: "bold"}}>{controls.channelName}</Typography>
        <Typography sx={{fontWeight: "bold", mb:1}}>Call Ended</Typography>
        <Chip color="default" label={`${("0" + Math.floor(props.time/3600)).slice(-2)}:${("0" + Math.floor(props.time/60)).slice(-2)}:${("0" + props.time%60).slice(-2)}`} sx={{fontSize:"30px", mb:"20px"}}/>
        
        <Typography variant="h6" style={styles.title} textAlign="center">
            {submited?"Thank you.":"Kindly rate my service to you."}
          </Typography>

          {submited?
          <div></div>: 
          <Box>
            <Box display="flex" justifyContent="center" width="100%">
                <Rating name="simple-controlled"
                        style={styles.rating}
                        value={rating}
                        onChange={handleRatingChange}
                    />
            </Box>
           
            <TextField
                style={styles.commentBox}
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                placeholder="You can also drop a comment..."
                value={comment}
                onChange={handleCommentChange}
            />
            <Button variant="contained" style={styles.submitButton} onClick={handleSubmit}>
                SUBMIT
            </Button>
          </Box>}
        </Card>
      </div>
  
    );
}
