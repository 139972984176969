import { Chip, Typography } from '@mui/material'
import React from 'react'

import { grey } from '@mui/material/colors';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import controls from './controls';


const CallAnswered = (props) => {
  // console.log("CALL ANSWERED");
  return (
    <>
    <Chip label={`${("0" + Math.floor(props.time/3600)).slice(-2)}:${("0" + Math.floor(props.time/60)).slice(-2)}:${("0" + props.time%60).slice(-2)}`} sx={{fontSize:"30px", p:"10px"}}/>
        <PersonRoundedIcon  sx={{fontSize:"200px", color:grey[500]}}/>
        <Typography sx={{ fontWeight: "bold"}}>{controls.channelName}</Typography>
        <Typography sx={{ fontWeight: "light"}}>Connected</Typography>
    </>
  )
}

export default CallAnswered
