import React from 'react'

import { grey } from '@mui/material/colors';
import { Paper, Typography } from '@mui/material';
import controls from './controls';


const CallQueue = (props) => {
  // console.log("CALL QUEUE");


  return (
    <>
      <Typography variant="body1">
              You are number 
          </Typography>
          <Paper sx={{bgcolor:grey[500], mt:0, px:2, py:0.5}}>
            <Typography fontWeight="regular" variant='h2'>{props.noOnQueue <1 ?"--":(("0" + props.noOnQueue).slice(-2))}</Typography>
          </Paper>
          <Typography variant="body1">
            on the queue. 
          </Typography>
          <Typography marginTop={4} marginBottom={6} textAlign="center" variant="body1">
            A customer care representative<br/>will be with you shortly 
          </Typography>
            <iframe 
              // onLoad={()=>{console.log("LOOOADED")}} 
              width="300" height="170" 
              src={`https://www.youtube.com/embed/${controls.youtubeVideoId}?si=Ob-MFy_FM003Ik6m&autoplay=1`} 
              title="YouTube video player" 
              frameBorder="1" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
            
    </>
  )
}

export default CallQueue
