import { Button, Typography } from '@mui/material'
import React from 'react'
import controls from './controls';
import EndCall from './EndCall';


const CallEndedPage = (props) => {
  // console.log("CALL ANSWERED");

//   const [blinker, setBlinker] = useState(false)

//   if(firstTimeLoading){
//     firstTimeLoading = false;
//   setInterval(()=>{setBlinker((fBlinker)=>!fBlinker)}, 500);
//   }

  return (
    controls.assignedToAnotherRoom !== undefined? 
    <>
            <Typography sx={{fontWeight: "bold", mb:1}}>You have been reaasigned to {controls.assignedToAnotherRoom.toString().split(",")[0]} room.</Typography>
            <Button onClick={()=>window.open(`https://caller.frecall.com/?roomId=${controls.assignedToAnotherRoom.toString().split(",")[1]}&userId=${controls.callerId}`, "_self")} variant="contained" color="success">
                Go to {controls.assignedToAnotherRoom.toString().split(",")[0]} room.
            </Button>
    </>:
    <>
       <EndCall time={props.time}/>
    </>
  )
}

export default CallEndedPage
