import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { Box, CircularProgress, Container, LinearProgress, Typography } from '@mui/material';
import { getRoomDetails, getUsersOnCall } from './firebase';
import controls, { joinCallQueue } from './controls';


let firstRun = true;

export default function WelcomeDialog(props) {

  // console.log("WELCOME");

  const [open, setOpen] = useState(true);
  const [roomDetails, setRoomDetails] = useState({});

  const handleClickOK = async() => {
    setOpen(false);
    controls.setLoading([true, "Connecting to call center..."]);
    document.getElementById("myPlayer").pause();
    joinCallQueue().then((value)=>{
      if(value){
        getUsersOnCall(props.handleUserChange)
        props.setAcceptedTandC(true);
        controls.setLoading([false, ""]);
        controls.setAudio(()=> controls.queuedAudio);
        // document.getElementById("myPlayer").play();
      }
    })
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("myPlayer").pause();
  };

  if(firstRun){
    firstRun = false;
    getRoomDetails(setRoomDetails)
  }

  if((roomDetails.callAppId||"").length <2){
    setTimeout(()=>controls.setLoading([true, "Checking with call center..."]), 500);
  }

  // console.log("RRRRRRRRRR");

  return (
   ((roomDetails.online !== undefined && !roomDetails.online))? 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"We are offline.."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Our customer care line is currently unavailable.<br/>Please try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button variant='contained' onClick={()=>{controls.setLoading([false,'']);handleClose(); window.close()}}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
   :
      ( roomDetails.callAppId||"").length >2 && <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Welcome..."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dear Caller,<br/><br/>
            Please note that this call may be recorded for quality assurance & training purposes.
            <br/><br/>
            Do you want to proceed?
            <br/><br/>
            Please confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={handleClickOK}>
            OK
          </Button>
        </DialogActions>
      </Dialog>);
}