import * as React from 'react';
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let showSnackbar;

 function CustomizedSnackbars(props) {

  // console.log("SNACK BAR");

  const [[open, message, severity], setOpen] = React.useState([false,"",""]);

  showSnackbar = (m, s) => {
    setOpen([true, m, s]);
  };

  const handleClose = () => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setOpen([false,"",""]);
  };

  // setInterval(()=>{
  //   showSnackbar([true, "Testing", "warning"]);
  // }, 5000)

  return (
      <Snackbar anchorOrigin={{vertical:"top", horizontal:"right"}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={(severity||"").length<2?"success": severity} sx={{ width: '100%' }}>
          {message}!
        </Alert>
      </Snackbar>
     
  );
}
export default CustomizedSnackbars;
export {showSnackbar};



      // <Alert severity="error">This is an error message!</Alert>
      // <Alert severity="warning">This is a warning message!</Alert>
      // <Alert severity="info">This is an information message!</Alert>
      // <Alert severity="success">This is a success message!</Alert> 
