import React, { useState } from 'react';
import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, ButtonGroup, Fab, Popover, Stack } from '@mui/material';
// import PropTypes from "prop-types";
// import "./css.css"
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeDownRoundedIcon from '@mui/icons-material/VolumeDownRounded';
import CallAnswered from './CallAnswered';
import CallQueue from './CallQueue';
import WelcomeDialog from './WelcomeDialog';
import controls, { endCall, joinCall } from './controls';
import { showSnackbar } from './Snackbar';
import CallEndedPage from './CallEndPage';



export default function CallPage() {

  // console.log("CALL PAGE");

  const [noOnQueue, setNoOnQueue] = useState(0)
  const [callAnswerd, setCallAnswerd] = useState(false)
  const [callEnded, setCallEnded] = useState(false)
  const [acceptedTandC, setAcceptedTandC] = useState(false)
  const [time, setTime] = useState(controls.callDuration);
  const [micOn, setMicOn] = useState(controls.micOn);
  // const [speakerOn, setSpeakerOn] = useState(controls.speakerOn);
  const [anchorEl, setAnchorEl] = useState(null);
  const [playbackDevices, setPlaybackDevices] = useState([]);

  const open = Boolean(anchorEl);
  const id = open ? 'more-menu' : undefined;


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = async(event) => {
    const ct = event.currentTarget;
    setPlaybackDevices(controls.playbackDevices);
    setAnchorEl(ct);
  };

  const handleMicToggle = ()=>{
    controls.micOn = !controls.micOn;
    setMicOn(controls.micOn);
    try{
        controls.microphoneTrack.setEnabled(controls.micOn)
    } catch(err){}
    }

//   const handleSpeakerToggle = async()=>{
//     const playbackDevices = await AgoraRTC.getPlaybackDevices(true);
// console.log(playbackDevices);
//       // controls.speakerOn = !controls.speakerOn;
//       // setSpeakerOn(controls.speakerOn);
//       // try{
//       //     controls.microphoneTrack.setEnabled(controls.micOn)
//       // } catch(err){}
//     }


   const handleUserChange = async(doc) =>{
    // console.log(doc);

    // console.log(controls.callerId)
    const noOnQueue = doc.usersOnQueue.indexOf(controls.callerId);
    controls.assignedToAnotherRoom = doc[`${controls.callerId}-queue`];

    console.log(noOnQueue);

    if(noOnQueue === -1){
        try{
          controls.unsub();
          endCall();
          setCallEnded(true);
        }
      catch (error) {
      }
    }
    setNoOnQueue(noOnQueue + 1);
    const agentName = doc[controls.callerId] || "";
    if(agentName.length >= 2 && !controls.callAnswered){
      controls.callAnswered = true;
      controls.channelName =  agentName;
      showSnackbar(`Agent, ${controls.channelName} has been assigned to your call.Now connecting you...`);
      controls.setLoading([true, `Connecting you to agent, ${controls.channelName}`]);
      await joinCall(setTime);
      controls.setLoading([false, ""]);
      setCallAnswerd(true);
      controls.setAudio(()=> controls.readyAudio);

    }
   }

  return (
    !acceptedTandC? <WelcomeDialog setAcceptedTandC={setAcceptedTandC} handleUserChange={handleUserChange} />:
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: "20vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
            {/* <LockOutlinedIcon /> */}
          {/* </Avatar> */}
          {
            callEnded? <CallEndedPage time={time}/>:(callAnswerd? <CallAnswered time={time}/>: <CallQueue noOnQueue={noOnQueue}/>)
          } 
          {/* CONTROLLS */}
          
            <Box sx={{ '& > :not(style)': { m: 1 }, bottom: 0,
              display:"flex",
              marginBottom: 2,
              position: "absolute" }}>
              { callEnded? 
              <></>
              :<Stack marginTop={6} direction="row" spacing={6}>
                <Fab onClick={handleMicToggle} aria-label="toggle mic">
                  {micOn?<MicIcon />:<MicOffIcon/>}
                </Fab>
                <Fab onClick={handleClick} aria-describedby={id} aria-label="Change speaker">
                  {true?<VolumeUp /> : <VolumeDownRoundedIcon/>}
                </Fab>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}>
                    <ButtonGroup  variant="contained" orientation="vertical" size="small" color="grey" aria-label="alignement button group">
                    {playbackDevices.filter((device) => !device.label.toLowerCase().includes("default")).map((device) => 
                      { 
                        return <Button onClick={()=>{
                          try{
                            handleClose();
                            controls.audioTrackOfAgent.setPlaybackDevice(device.deviceId);
                          }catch(err){}
                        }} disabled={playbackDevices.filter((d) => d.groupId === device.groupId).length>1} id={device.deviceId}>{device.label}</Button>}
                    )}
                        {/* <Button>Copy uid</Button>
                        <Button color="warning">Queue back</Button>
                        <Button color="warning">Send to Ops Q</Button> */}
                    </ButtonGroup>
                </Popover>
                <Fab onClick={
                  ()=>{endCall();  setCallEnded(true);}
                  }  color="error" aria-label="End call">
                  <CallEndIcon />
                </Fab>
              </Stack> }
          </Box> 
          
        </Box>
      </Container>
  );
}

